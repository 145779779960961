var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',[_vm._v(" Object List "),_c('v-spacer'),_c('v-text-field',{staticClass:"pa-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.items.data,"server-items-length":_vm.items.total,"options":_vm.pagination,"footer-props":{
        'items-per-page-options': [5,10,20,30,50,100,-1],
      },"loading":_vm.loading,"item-key":"name"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.file_url",fn:function(ref){
      var item = ref.item;
return [(item.type=='IMAGE')?_c('v-btn',{attrs:{"color":"grey darken-2 px-2","small":"","dark":""},on:{"click":function($event){return _vm.showPreview(item)}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-image-area")]),_vm._v(" View ")],1):_vm._e(),(item.type=='VIDEO')?_c('v-btn',{attrs:{"color":"grey darken-2 px-2","small":"","dark":""},on:{"click":function($event){return _vm.showPreview(item)}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-play")]),_vm._v(" Play ")],1):_vm._e(),(item.type=='PDF')?_c('v-btn',{attrs:{"color":"grey darken-2 px-2","small":"","dark":""},on:{"click":function($event){return _vm.showPreview(item)}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-file-pdf-box")]),_vm._v(" View ")],1):_vm._e()]}},{key:"item.link",fn:function(ref){
      var item = ref.item;
return [(item.link)?_c('v-btn',{attrs:{"color":"grey darken-2","small":"","dark":"","href":item.link,"target":"_blank"}},[_vm._v(" Open link ")]):_c('span',{staticClass:"align-center items-center"},[_vm._v(" - ")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"grey darken-2","icon":"","dark":"","to":'/object/edit/'+item.object_id}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})],1),_c('v-btn',{attrs:{"color":"primary","fixed":"","fab":"","bottom":"","right":"","to":"/object/add"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.show_preview)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.preview_data),callback:function ($$v) {_vm.preview_data=$$v},expression:"preview_data"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2 rounded-lg px-0"},[(_vm.preview_data.type=='VIDEO')?_c('video',{attrs:{"src":_vm.preview_data.upload_url,"width":"100%","controls":""}}):_vm._e(),(_vm.preview_data.type=='IMAGE')?_c('v-img',{attrs:{"src":_vm.preview_data.upload_url,"contain":"","max-height":"300px"}}):_vm._e(),(_vm.preview_data.type=='PDF')?_c('iframe',{attrs:{"src":_vm.preview_data.upload_url+'#view=fitH&toolbar=0',"width":"100%","height":"300px"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-4","text":""},on:{"click":function($event){_vm.show_preview = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }